<template>
  <component
    :is="is || 'a'"
    ref="link"
    class="cursor-pointer"
    v-bind="$attrs"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click.stop.prevent="
      () => {
        shouldClose = false;
        dialogModel = true;
      }
    "
  >
    <slot name="link">
      <p class="mb-0">{{ label }}</p>
    </slot>
  </component>

  <q-popup-proxy
    v-model="isDialogOpen"
    no-parent-event
    transition-show="jump-up"
    transition-hide="jump-down"
    :target="link"
    :anchor="anchor"
    :offset="offset ?? [0, 15]"
    :self="self"
  >
    <Card
      :class="['dialogCard', cardClasses]"
      style="overflow: auto; height: 100%; width: 100%"
      @mouseenter="shouldClose = false"
      @mouseleave="
        () => {
          shouldClose = true;
          onMouseLeave();
        }
      "
    >
      <CardHeader v-if="!hideHeader" dense :icon="icon || 'mdi:information'">
        {{ title }}
      </CardHeader>
      <slot>
        <CardSection>
          <slot>
            <p class="mb-0">{{ content }}</p>
          </slot>
        </CardSection>
      </slot>
    </Card>
  </q-popup-proxy>
</template>

<script setup lang="ts">
import { CardHeader } from "#components";

import { computed, ref } from "vue";
import { type QMenuProps, useQuasar } from "quasar";
import { useTimeoutFn } from "@vueuse/core";

const props = defineProps<{
  title: string;
  content?: string;
  is?: string;
  label?: string;
  icon?: string;
  hideHeader?: boolean;

  cardClasses?: string;

  anchor?: QMenuProps["anchor"];
  self?: QMenuProps["self"];
  offset?: QMenuProps["offset"];

  disabled?: boolean;
}>();

const { start: startCloseTimeout, stop: stopCloseTimeout } = useTimeoutFn(
  () => {
    if (timeoutToOpen !== null) clearTimeout(timeoutToOpen);
    if (shouldClose.value) dialogModel.value = false;
  },
  400
);

const dialogModel = ref(false);
const isDialogOpen = computed(() => {
  return props.disabled ? false : dialogModel.value;
});
const link = ref<HTMLParagraphElement>();
let timeoutToOpen: ReturnType<typeof setTimeout> | null = null;
const shouldClose = ref(false);

const $q = useQuasar();

const onMouseEnter = () => {
  if ($q.screen.gt.sm) {
    stopCloseTimeout();
    timeoutToOpen = setTimeout(() => {
      if (!dialogModel.value) shouldClose.value = true;
      dialogModel.value = true;
    }, 300);
  }
};

const onMouseLeave = () => {
  if (timeoutToOpen !== null) clearTimeout(timeoutToOpen);
  startCloseTimeout();
};
</script>

<style lang="scss">
.dialogCard {
  min-width: 40ch;
}
</style>
